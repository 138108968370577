import { BehaviorSubject } from "rxjs";
import { AppointmentSettingsEntry } from "src/app/data_model/appointment-settings";
import { PracticeEntry } from "src/app/data_model/practice";
import { Injectable } from "@angular/core";
import { GRAPHQL_OPERATION_NAMES } from "./http.service";
import { CommonEntry } from "src/app/data_model/common";
import { GAService } from "./ga.service";
import { JWTService } from "./jwt.service";
import { PatientsService } from "./patients.service";
import { BrandService } from "./brand.service";
import { fromBrandInfoBase } from "src/app/data_model/brand-info";
import Bugsnag from "@bugsnag/js";
import { HOUR_IN_MS } from "@shared/time-constants";
import { CacheService } from "./cache.service";
import { Constants } from "src/constants";
import { LocationService } from "./location.service";
import { FeatureFlagsService } from "./feature-flags.service";
import { BrandInfoSiteBase } from "@backend/graph/brand_info/brand-info-base";
import { environment } from "src/environments/environment";
import { NavigationService } from "./navigation.service";
import { E_ManageFeatures } from "@backend/common/enums/feature-flags.enum";
import { AcquisitionSourceEntry } from "src/app/data_model/acquisition-source";
import { InactivityMonitorService } from "./inactivity-monitor.service";
import { filterDefined } from "../utils/rxjs";
import { CachedHttpService } from "./cached-http.service";
import { LocalisationService } from "./localisation.service";
import { PortalInPracticeDataService } from "./portal-in-practice-data.service";
import { SmileGoalsBase } from "@backend/graph/smile-goals/smile-goals-base";
import { SmileGoalEntry, SmileGoalsMotivationEntry, SmileGoalsServiceEntry, SmileGoalsTimeframeEntry } from "src/app/data_model/smile-goals";
import { notifyAndLeaveBreadcrumb } from "../utils/logging";

const COMMON_QUERY = (uri: string) => `{
  practice {
    currency_code
    dial_code
    iso_country_code
    time_zone
    phone_number
    is_multisite
    name
    show_acquisition_source
    show_emergency_contact
    show_patient_gp_details
    show_smile_goals
    supports_nhs_pr
    acquisition_sources {
      items {
        id
        name
        show_in_portal
      }
    }
    appointment_settings {
      practice_id
      allow_appointment_cancellations
      show_all_slots_new_patients
      required_to_select_site
      show_all_slots_existing_patients
      min_wait_time
      max_wait_time
      max_days_ahead_bookings
      can_book_together
      hygiene_lapsing_exam_types
    }
    smile_goals {
      goals {
        value
        heading
        description
        motivations {
          value
          heading
        }
      }
      timeframes {
        value
        heading
      }
    }
  }
  brand_info(uri: "${uri}") {
    id
    appointment_list_first
    existing_patients_only_on_brand_url
    ga_tracking_cookie_domain
    ga_tracking_id
    ga_tracking_link_domain
    site_locking_enabled
    sites {
      site_id
      site_name
      site_phone_number
      site_email_address
      site_website
      site_address_line_1
      site_address_line_2
      site_address_line_3
      site_town
      site_county
      site_postcode
      stripe_account_id
      settings {
        forms__nhs_pr
        forms__medical_history
        forms__treamtent_plan_estimates
        payments__balance_payments
      }
    }
  }
}
`;

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private _dataLoaded = false;
  private _commonData: CommonEntry;
  private _onInitData: BehaviorSubject<CommonEntry | null> = new BehaviorSubject(null);
  private _isFetching: boolean;
  constructor(
    private _brandService: BrandService,
    private _patientsService: PatientsService,
    private _jwtService: JWTService,
    private _gaService: GAService,
    private _cacheService: CacheService,
    private _locationService: LocationService,
    private _featureFlagsService: FeatureFlagsService,
    private _navigationService: NavigationService,
    private _inactivityMonitorService: InactivityMonitorService,
    private _cachedHttpService: CachedHttpService,
    private _localisationService: LocalisationService,
    private _portalInPracticeDataService: PortalInPracticeDataService
  ) {}

  public get onInitData() {
    return this._onInitData.pipe(filterDefined());
  }

  public get isCommonDataLoaded(): boolean {
    return this._dataLoaded;
  }

  public get practice(): PracticeEntry {
    return this._commonData.practice;
  }

  public get max_days_ahead_bookings(): number {
    return this._commonData.practice.appointment_settings.max_days_ahead_bookings;
  }

  public get siteCountyCodeForPatient(): string | undefined {
    const patientSiteId = this._patientsService.patientInfo?.site_id;
    const sites = this._commonData.brand_info?.sites;

    if (patientSiteId) {
      const matchingSite = sites?.find((site) => site.site_id === patientSiteId);
      if (matchingSite) {
        //use the county on the patient's site is available
        return matchingSite.site_county;
      }
    }

    //otherwise just use the county from the first site
    return sites?.[0]?.site_county;
  }

  public get urlSiteId(): string {
    return this._brandService.restrictedSiteId;
  }

  public getSitePhoneNumber(siteId: string): string | null {
    const site = this._commonData.brand_info.sites.find((s) => s.site_id === siteId);
    if (site) return site.site_phone_number;
    return null;
  }

  public getSite(site_id: string): BrandInfoSiteBase | undefined {
    return this._commonData.brand_info.sites.find((site) => site.site_id === site_id);
  }

  private _handleDomainInfo200(graphData: any, pathname: string): void {
    delete graphData.code;
    delete graphData.redirect_uri;
    const forceSwap = pathname === "/login/redirect";
    const { feature_flags, manage_features, iso_country_code } = graphData;

    this._jwtService.setPublicToken(graphData.public_jwt, forceSwap);
    this._cacheService.setJson(Constants.BRAND_INFO_STORAGE_KEY, graphData, this._cacheService.getRelativeTtl(HOUR_IN_MS));

    // If the manage feature is off, then override the favicon url to null
    if (!manage_features || !manage_features[E_ManageFeatures.CUSTOM_FAVICON]) graphData.favicon_preview_url = null;

    this._featureFlagsService.setFeatureFlags(feature_flags);
    this._brandService.setupBrandingConfig(graphData);

    Bugsnag.leaveBreadcrumb("setting iso_country_code", { iso_country_code });
    this._localisationService.country_code = iso_country_code;

    if (this._featureFlagsService.logoutOnInactivity) {
      this._inactivityMonitorService.start();
    }
  }

  private _handleDomainInfo302(graphData: any, pathname: string): void {
    if (this._locationService.isPairDomain) {
      console.log("Got 302 on pair domain, updating PiP data and reloading");
      this._portalInPracticeDataService.updatePipDataSiteUrl(`https://${graphData.redirect_uri}`);
      window.location.reload();
      return;
    }

    window.open(`https://${graphData.redirect_uri}/${(pathname || "").replace(/^\//, "")}`, "_self");
  }

  public getDomainInfo(): Promise<void> {
    const { hostname, pathname } = this._locationService;
    const url = `${environment.REST_URL}/api/domains/info?uri=${hostname}`;

    return new Promise<void>((resolve, reject) => {
      this._cachedHttpService.send(url, { method: "GET" }, { notPatientSpecific: true, ttl: 3600 }).subscribe(
        (graphData: any) => {
          switch (graphData.code) {
            case 200:
              this._handleDomainInfo200(graphData, pathname);
              break;

            case 302:
              this._handleDomainInfo302(graphData, pathname);
              break;

            case 404:
              this._handleDomainInfo404(graphData);
              // Reject the promise to prevent the app from loading and allow the error or pair routes to load correctly
              reject();
              return;

            default:
              throw new Error(`Unhandled error code ${graphData.code} from domains/info`);
          }
          resolve();
        },
        (err) => {
          Bugsnag.leaveBreadcrumb("Error getting brand info", { code: "28ba0137", err });
          Bugsnag.notify("activating error route");
          this._navigationService.navigate("error");
          reject(err);
        }
      );
    });
  }
  private _handleDomainInfo404(graphData: any) {
    Bugsnag.leaveBreadcrumb("Unknown error code getting brand info", { code: "e15f44b0", httpCode: graphData.code });

    if (this._locationService.isPairDomain) {
      Bugsnag.notify("Error getting brand info on pair domain, deleting PiP data and navigating to pair");
      this._portalInPracticeDataService.deletePipData();
      this._navigationService.navigate("pair");
      return;
    }
    Bugsnag.notify("activating error route");
    this._navigationService.navigate("error");
  }

  public getCommonData(force = false): void {
    const jwt = this._jwtService.getJWT();

    // If we are already fetching, then wait until the fetch is complete and resolve the data
    if (this._isFetching) {
      const interval = setInterval(() => {
        if (!this._isFetching) {
          clearInterval(interval);
        }
      }, 300);
      return;
    }

    if (!force && this._dataLoaded) {
      this._onInitData.next(this._commonData);
      this._dataLoaded = true;
      return;
    }
    if (jwt) {
      this._isFetching = true;

      Bugsnag.leaveBreadcrumb("getting common data");

      this._cachedHttpService
        .query<any>(GRAPHQL_OPERATION_NAMES.COMMON, COMMON_QUERY(this._locationService.hostname), { notPatientSpecific: true, ttl: 3600 })
        .subscribe(
          (response: any) => {
            if (response.errors) {
              notifyAndLeaveBreadcrumb("Error fetching common data", { errors: response.errors });
              this._onInitData.error({ errors: response.errors });
              return;
            }

            const common_entry = new CommonEntry();
            const { practice } = response.data;

            if (!practice) {
              return;
            }

            // Setup entries
            const practice_entry = new PracticeEntry(practice);
            const appointment_settings_entry = new AppointmentSettingsEntry(practice.appointment_settings);
            const acquisition_sources = new Array<AcquisitionSourceEntry>();

            if (practice.acquisition_sources.items)
              for (const source of practice.acquisition_sources.items) acquisition_sources.push(new AcquisitionSourceEntry(source));

            // Setup practice data
            if (!practice_entry.iso_country_code) practice_entry.iso_country_code = "GB";
            if (!practice_entry.time_zone) practice_entry.time_zone = "Europe/London";
            if (!practice_entry.currency_code) practice_entry.currency_code = "GBP";
            if (!practice_entry.dial_code) practice_entry.dial_code = "44";
            practice_entry.appointment_settings = appointment_settings_entry;
            practice_entry.acquisition_sources.items = acquisition_sources;
            const smile_goal_data = this._setupSmileGoals(practice.smile_goals, practice.show_smile_goals);
            practice_entry.smile_goals = smile_goal_data;

            // Setup common data
            this._commonData = common_entry;
            this._commonData.practice = practice_entry;
            this._commonData.brand_info = response.data.brand_info;
            this._gaService.setup(response.data.brand_info);
            this._brandService.brand = fromBrandInfoBase(response.data.brand_info, this._patientsService.patientInfo);

            this._onInitData.next(this._commonData);
            this._dataLoaded = true;
            this._isFetching = false;

            this._featureFlagsService.forceBasketlessBooking = !this._commonData.brand_info.sites.some((site) => !site.stripe_account_id);
          },
          (err) => {
            notifyAndLeaveBreadcrumb("Error fetching common data", { error: err.message || err });
            this._onInitData.error(err);
          }
        );
    } else {
      notifyAndLeaveBreadcrumb("No JWT to allow common data query");

      this._onInitData.error("No JWT");
    }
  }

  private _setupSmileGoals(smile_goals: SmileGoalsBase, show_smile_goals: boolean): SmileGoalEntry {
    if (!show_smile_goals || !smile_goals) {
      return {
        goals: [],
        timeframes: [],
      };
    }

    const { goals, timeframes } = smile_goals;
    const goal_entries = new Array<SmileGoalsServiceEntry>();
    const timeframe_entries = new Array<SmileGoalsTimeframeEntry>();

    for (const goal of goals) {
      const motivation_entries = new Array<SmileGoalsMotivationEntry>();
      for (const motivation of goal.motivations) {
        motivation_entries.push(new SmileGoalsMotivationEntry(motivation));
      }
      goal.motivations = motivation_entries;
      goal_entries.push(new SmileGoalsServiceEntry(goal));
    }

    for (const timeframe of timeframes) {
      timeframe_entries.push(new SmileGoalsTimeframeEntry(timeframe));
    }

    return {
      goals: goal_entries,
      timeframes: timeframe_entries,
    };
  }
}
