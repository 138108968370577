import { AddressableItem } from "../../common/addressable-item";
import { AppointmentBase } from "../appointments/appointment-base";
import { PractitionerBase } from "../practitioners/practitioner-base";

export class CancellationPolicyBase {
  public readonly can_cancel_online = true;
  public readonly can_cancel_online_up_to_hours = 48;
  public readonly charge_if_less_than_hours = 0;
  public readonly charge_per_minute = 0;
}

export class PatientStatsBase extends AddressableItem {
  public first_appointment_date: string | null;
  public first_exam_date: string | null;
  public last_appointment_date: string | null;
  public last_cancelled_appointment_date: string | null;
  public last_exam_date: string | null;
  public last_fta_appointment_date: string | null;
  public last_scale_and_polish_date: string | null;
  public next_appointment_date: string | null;
  public next_exam_date: string | null;
  public next_scale_and_polish_date: string | null;
  public nhs_exemption_code: string | null;
  public can_book_together_dental: boolean;
  public can_book_together_hygiene: boolean;
}

export enum E_ActionType {
  SmileGoal = "smile_goal",
  GP17Part1 = "gp17_part1",
  GP17Part2 = "gp17_part2",
  MedicalHistory = "medical_history",
  PendingPayment = "pending_payment",
  FP17 = "fp17",
}

export enum E_ActionStatus {
  Pending = "pending",
  Complete = "complete",
  NotApplicable = "not_applicable",
  InProgress = "in_progress",
}

type Action_SharedFields = {
  status: E_ActionStatus;
  required: boolean;
  appointments: AppointmentBase[];
};

export type Action_Metadata_TreatmentPlan = {
  completed_date: string;
  total_cost: number;
  appointment_id: number;
};

export type Action_Metadata_Appointment = {
  appointment_id: string;
  appointment_sid: number;
  practitioner_name: string;
  site_id: string;
};

export type Action_GP17_Metadata = {
  exemption_id: string | null;
  treatment_plans: Array<Action_Metadata_TreatmentPlan> | null;
  appointments: Array<Action_Metadata_Appointment> | null;
};

export type Action_FP17_Metadata = {
  exemption_id: string | null;
  appointments: Array<Action_Metadata_Appointment> | null;
  locked: boolean;
};

export type Action_SmileGoal_Metadata = {
  smile_goal: {
    state: number;
    goal: number;
    motivation: number;
    timeframe: number;
    id: string;
    patient_id: string;
    treatment_plan_id: string;
    practice_id: number;
    created_at: string;
    updated_at: string;
  };
};

export type Action_MedicalHistory_Metadata = {
  medical_history_id: string;
};

export type Action_NHSPR_Metadata = Action_FP17_Metadata | Action_GP17_Metadata;

export type Action_SmileGoal = {
  action_type: E_ActionType.SmileGoal;
  metadata: Action_SmileGoal_Metadata;
} & Action_SharedFields;

export type Action_GP17Part1 = {
  action_type: E_ActionType.GP17Part1;
  metadata: Action_GP17_Metadata;
} & Action_SharedFields;

export type Action_GP17Part2 = {
  action_type: E_ActionType.GP17Part2;
  metadata: Action_GP17_Metadata;
} & Action_SharedFields;

export type Action_MedicalHistory = {
  action_type: E_ActionType.MedicalHistory;
  metadata: Action_MedicalHistory_Metadata;
} & Action_SharedFields;

export type Action_PendingPayment = {
  action_type: E_ActionType.PendingPayment;
  metadata: Record<string, any>;
} & Action_SharedFields;

export type Action_FP17 = {
  action_type: E_ActionType.FP17;
  metadata: Action_FP17_Metadata;
} & Action_SharedFields;

export type ActionMetadata = Action_GP17_Metadata | Action_FP17_Metadata | Action_SmileGoal_Metadata | Action_MedicalHistory_Metadata;

export type Action = Action_GP17Part1 | Action_GP17Part2 | Action_FP17 | Action_MedicalHistory | Action_PendingPayment | Action_SmileGoal;

export class PatientBase extends PatientStatsBase {
  // From Patient
  public acquisition_source_id: string;
  public actions?: Action[]; //only returned from the PMS if we pass in query params action=true
  public account_id: number;
  public active: boolean;
  public bad_debtor: boolean;
  public created_at: Date;
  public date_of_birth: string;
  public dentist: PractitionerBase;
  public dentist_id: string;
  public dentist_recall_date: string | null;
  public dentist_recall_interval: number | null | undefined;
  public email_address: string;
  public emergency_contact_name: string | null;
  public emergency_contact_phone: string | null;
  public emergency_contact_phone_normalized: string | null;
  public emergency_contact_phone_country: string;
  public emergency_contact_relationship: string | null;
  public ethnicity: string;
  public first_name: string;
  public gender: "M" | "F";
  public gp_practice_name: string;
  public gp_practice_address_line_1: string;
  public gp_practice_address_line_2: string;
  public gp_practice_address_line_3: string;
  public gp_practice_address_line_4: string;
  public gp_practice_address_line_5: string;
  public gp_practice_postcode: string;
  public gp_practice_phone: string;
  public gp_practice_phone_normalized: string;
  public gp_practice_phone_country: string;
  public home_phone_country: string;
  public home_phone: string;
  public hygienist_id: string;
  public hygienist_recall_date: string | null;
  public hygienist_recall_interval: number | null | undefined;
  public id: string; // unique within the practice but reused for different practices
  public last_name: string;
  public marketing: boolean;
  public mobile_phone_country: string;
  public mobile_phone_normalized: string;
  public mobile_phone: string;
  public nhs_number: string;
  public ni_number: string;
  public payment_plan_id: string;
  public preferred_phone_number: number;
  public prevent_appointment_booking: boolean;
  public recall_method: string;
  public site_id: string;
  public stripe_id: string;
  public title: string;
  public uuid: string; // globally unique
  public use_email: boolean;
  public use_sms: boolean;
  public work_phone_country: string;
  public work_phone: string;

  // Added by us
  public age: number;
  public cancellation_policy: CancellationPolicyBase;
  public is_nhs: boolean;
  public last_impersonator_login: Date | null;
  public last_patient_login: Date | null;
  public nhs_country: string;
  public portal_url: string;
  public portal_url_full: string;
  public portal_url_is_old_world: boolean;
  public registered_portal_user: boolean;
  public is_account_locked?: boolean;
}
